import { FormLabel as ChakraFormLabel, FormLabelProps } from "@chakra-ui/react";

export const FormLabel = (props: FormLabelProps) => {
  return (
    <ChakraFormLabel
      fontWeight={"500"}
      fontSize={".875rem"}
      lineHeight={"1.25rem"}
      color={"neutral.700"}
      {...props}
    />
  );
};
