import { Button, Flex, HStack, Image, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure, useTheme } from "@chakra-ui/react";
import { Navigate, useLocation } from "react-router-dom";
import { NetworkStatus, useQuery } from "@apollo/client";
import { Tenant } from "../../__generated__/resolvers-types";
import CreateTenantModal from "../../components/modals/create";
import useQueries from "./queries";
import { AddIcon } from "@chakra-ui/icons";

import TenantRow from "./row";
import EditTenantModal from "../../components/modals/edit";

export type ColumnNames<T> = Exclude<keyof T, "__typename">[];

const ListScreen = () => {
	// Attributes
	const theme = useTheme();
	const { GetTenants } = useQueries();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const columns: ColumnNames<Tenant> = ["name", "domaine", "language", "pack", "createdAt"];

	const { state } = useLocation();
	const prefix = state?.prefix;

	// Queries
	const { data } = useQuery<{
		tenants: Tenant[];
	}>(GetTenants, {
		variables: {
			prefix,
		},
		pollInterval: 2000,
	});

	if (!prefix) {
		return <Navigate to="/" replace={true} />;
	}

	// Render
	return (
		<>
			<Flex flexDirection={"column"} flex={1} backgroundColor={"#F3F4F6"} minH={"100vh"} h="full">
				<HStack bgColor={"white"} w="full" justifyContent={"space-between"} height={"5rem"} px="4rem" borderBottomColor="neutral.100" borderWidth={1} alignItems={"center"}>
					<Image src="vert-logo.png" height={"2rem"} />
					<Button leftIcon={<AddIcon />} variant={"primary"} onClick={onOpen}>
						Create new tenant
					</Button>
				</HStack>

				<Flex w="full" justify={"center"} p="4rem">
					<TableContainer borderWidth={1} borderColor={"neutral.100"} flex={1} bgColor={"white"} borderRadius={12}>
						<Table variant="simple">
							<Thead bgColor={theme.colors.primary["500"]}>
								<Tr>
									<Th color={"white"}>Live</Th>
									{columns.map((column, index) => (
										<Th key={index} color={"white"}>
											{column}
										</Th>
									))}
									<Th color={"white"}>User Admin</Th>
									<Th color={"white"}>User Worker</Th>
									<Th color={"white"}>Admin</Th>
									<Th color={"white"}>Worker</Th>
									<Th color={"white"}>Edit</Th>
									<Th color={"white"}>Delete</Th>
								</Tr>
							</Thead>
							<Tbody>
								{data?.tenants?.map((row, rowIndex) => (
									<TenantRow key={rowIndex} tenant={row} columns={columns} />
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</Flex>
			</Flex>
			<EditTenantModal isOpen={isOpen} onClose={onClose} />
		</>
	);
};

export default ListScreen;
