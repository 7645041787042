import { gql } from "@apollo/client";

const useQueries = () => {
	// Queries
	const GetTenants = gql`
		query getTenants($prefix: String) {
			tenants(prefix: $prefix) {
				id
				name
				domaine
				pack
				language
				admin
				worker
				createdAt
				email
				valid
				secondaryEmail
				phone
				secondaryPhone
				website
				tvaNumber
				address {
					street
					number
					postalCode
					locality
					country
				}
			}
		}
	`;

	const CreateTenant = gql`
		mutation CreateTenant($input: TenantInput) {
			createTenant(input: $input) {
				name
				domaine
				pack
				language
				createdAt
			}
		}
	`;

	const UpdateTenant = gql`
		mutation UpdateTenant($input: UpdateTenantInput) {
			updateTenant(input: $input) {
				id
			}
		}
	`;

	const DeleteTenant = gql`
		mutation DeleteTenant($input: DeleteTenantInput) {
			deleteTenant(input: $input)
		}
	`;

	return {
		GetTenants,
		CreateTenant,
		UpdateTenant,
		DeleteTenant,
	};
};

export default useQueries;
