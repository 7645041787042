import {
  Button,
  Flex,
  FormControl,
  HStack,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const EnterCodeScreen = () => {
  // Attributes
  const navigate = useNavigate();
  const [code, setCode] = useState<string>("");

  // Handlers
  const handleSubmit = () => {
    navigate("/tenants", { state: { prefix: code } });
  };

  // Render
  return (
    <Flex
      justify={"center"}
      alignItems={"center"}
      flex={1}
      backgroundColor={"#F3F4F6"}
      minH={"100vh"}
    >
      <VStack
        justify={"space-between"}
        backgroundColor={"white"}
        borderRadius={".875rem"}
        border="1px solid #E5E7EB"
        width="25rem"
        alignItems={"center"}
        height={"12.5rem"}
        py="2.5rem"
        px="2rem"
      >
        <Image src="vert-logo.png" width={"10rem"} />
        <VStack w="full" alignItems={"flex-start"}>
          <HStack w="full">
            <FormControl>
              <Input
                placeholder="Enter your identification prefix..."
                value={code}
                onChange={(e) => setCode(e?.target?.value)}
              />
            </FormControl>
            <Button
              colorScheme="blue"
              type="submit"
              onClick={handleSubmit}
              isDisabled={code === ""}
            >
              Go
            </Button>
          </HStack>
        </VStack>
      </VStack>
    </Flex>
  );
};

export default EnterCodeScreen;
