import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const primary = defineStyle({
  background: "primary.500",
  color: "white",
  fontSize: "14px",
  fontWeight: "600",
  borderRadius: ".25rem",
  _hover: {
    backgroundColor: "primary.600",
    _disabled: { background: "primary.500" },
  },
  _active: {
    backgroundColor: "primary.400",
    transform: "scale(0.97)",
    transition: "all 300ms ease-in-out",
  },
});

const secondary = defineStyle({
  background: "white",
  borderColor: "neutral.200",
  borderWidth: 1,
  color: "black",
  fontSize: "14px",
  fontWeight: "600",
  borderRadius: ".25rem",
  _active: {
    transform: "scale(0.97)",
    transition: "all 300ms ease-in-out",
  },
});

export const buttonTheme = defineStyleConfig({
  variants: {
    primary,
    secondary,
  },
});
