import * as React from "react";
import * as ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import EnterCodeScreen from "./screens/code";
import ListScreen from "./screens/tenants";
import { ChakraProvider } from "@chakra-ui/react";
import { ApolloProvider } from "@apollo/client";
import { createApolloClient } from "./services/apollo-client";
import theme from "./theme";

const router = createBrowserRouter([
  {
    path: "/",
    element: <EnterCodeScreen />,
  },
  {
    path: "/tenants",
    element: <ListScreen />,
  },
]);

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ApolloProvider client={createApolloClient()}>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
