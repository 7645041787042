import { extendTheme } from "@chakra-ui/react";
import { buttonTheme } from "./components/button";

export default extendTheme({
  components: { Button: buttonTheme },
  colors: {
    background: "#F3F4F6E5",
    primary: {
      50: "#E3EDFF",
      400: "#387AFF",
      500: "#0050E9",
      600: "#2945da",
    },
    base: {
      0: "#FFFFFF",
    },
    neutral: {
      50: "#F3F4F6",
      100: "#E5E7EB",
      200: "#D1D5DB",
      400: "#9CA3AF",
      500: "#6B7280",
      700: "#374051",
    },
    error: {
      500: "#EE4444",
    },
    danger: {
      50: "#FEF2F2",
      400: "#F2464B",
      900: "#981B1B",
    },
    success: {
      50: "#ECFDF5",
      500: "#279672",
      900: "#0C3F3A",
    },
  },
});
