import { Button, IconButton, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { ITenantRowProps } from "./props";
import { EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Tenant } from "../../../__generated__/resolvers-types";
import { LanguagesOptions, PackOptions } from "../constants";
import { format } from "date-fns";
import EditTenantModal from "../../../components/modals/edit";
import { useApolloClient, useMutation } from "@apollo/client";
import useQueries from "../queries";

const TenantRow = ({ tenant, columns }: ITenantRowProps) => {
	// Attributes
	const { isOpen: isEditModalOpen, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useDisclosure();
	const { DeleteTenant } = useQueries();
	const client = useApolloClient();

	// Mutations
	const [deleteTenant] = useMutation(DeleteTenant);

	const renderCell = (columnName: keyof Tenant, data: any) => {
		switch (columnName) {
			case "createdAt":
				return tenant.valid ? format(data, "yyyy-MM-dd HH:mm") : "-";
			case "pack":
				return PackOptions.find((pack) => pack.value === data)?.label;
			case "language":
				return LanguagesOptions.find((lg) => lg.value === data)?.label;
			default:
				return data;
		}
	};

	// Handlers
	const handleAdminLogin = (tenant: Tenant) => {
		window.open(
			// @ts-ignore
			`${process.env.REACT_APP_WEB_URL}/index.php?tenant_id=${tenant?.id}&user=${tenant?.admin}&type=admin`,
			"_blank"
		);
	};

	const handleWorkerLogin = (tenant: Tenant) => {
		window.open(
			// @ts-ignore
			`${process.env.REACT_APP_WEB_URL}/index.php?tenant_id=${tenant?.id}&user=${tenant?.worker}&type=worker`,
			"_blank"
		);
	};

	const handleDelete = () => {
		deleteTenant({
			variables: { input: { id: tenant?.id } },
			onCompleted: () => {
				client.refetchQueries({
					include: ["getTenants"],
				});
			},
		});
	};

	// Render
	return (
		<Tr>
			<Td>{tenant.valid ? "✅" : "❌"}</Td>
			{columns.map((column, colIndex) => (
				<Td key={colIndex}>{renderCell(column, tenant[column])}</Td>
			))}

			{tenant.valid ? <Td>{tenant.admin}</Td> : <Td>-</Td>}
			{tenant.valid ? <Td>{tenant.worker}</Td> : <Td>-</Td>}
			<Td>
				{tenant.valid ? (
					<Button rightIcon={<ExternalLinkIcon />} variant={"secondary"} colorScheme="blue" w="full" onClick={() => handleAdminLogin(tenant)}>
						Admin Login
					</Button>
				) : (
					"-"
				)}
			</Td>
			<Td>
				{tenant.valid ? (
					<Button rightIcon={<ExternalLinkIcon />} variant={"secondary"} w="full" onClick={() => handleWorkerLogin(tenant)}>
						Worker Login
					</Button>
				) : (
					"-"
				)}
			</Td>
			<Td>{tenant.valid ? <IconButton onClick={onOpenEditModal} variant={"secondary"} aria-label="edit" icon={<EditIcon />} /> : "-"}</Td>
			<EditTenantModal isOpen={isEditModalOpen} onClose={onCloseEditModal} tenant={tenant} />
			<Td onClick={handleDelete} display={"flex"} height="73px" justifyContent={"center"} alignItems={"center"}>
				<svg style={{ width: 15, height: 15, cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xmlSpace="preserve">
					<defs></defs>
					<g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
						<path d="M 76.777 2.881 H 57.333 V 2.412 C 57.333 1.08 56.253 0 54.921 0 H 35.079 c -1.332 0 -2.412 1.08 -2.412 2.412 v 0.469 H 13.223 c -1.332 0 -2.412 1.08 -2.412 2.412 v 9.526 c 0 1.332 1.08 2.412 2.412 2.412 h 63.554 c 1.332 0 2.412 -1.08 2.412 -2.412 V 5.293 C 79.189 3.961 78.109 2.881 76.777 2.881 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
						<path d="M 73.153 22.119 H 16.847 c -1.332 0 -2.412 1.08 -2.412 2.412 v 63.057 c 0 1.332 1.08 2.412 2.412 2.412 h 56.306 c 1.332 0 2.412 -1.08 2.412 -2.412 V 24.531 C 75.565 23.199 74.485 22.119 73.153 22.119 z M 33.543 81.32 c 0 1.332 -1.08 2.412 -2.412 2.412 h -2.245 c -1.332 0 -2.412 -1.08 -2.412 -2.412 V 30.799 c 0 -1.332 1.08 -2.412 2.412 -2.412 h 2.245 c 1.332 0 2.412 1.08 2.412 2.412 V 81.32 z M 48.535 81.32 c 0 1.332 -1.08 2.412 -2.412 2.412 h -2.245 c -1.332 0 -2.412 -1.08 -2.412 -2.412 V 30.799 c 0 -1.332 1.08 -2.412 2.412 -2.412 h 2.245 c 1.332 0 2.412 1.08 2.412 2.412 V 81.32 z M 63.526 81.32 c 0 1.332 -1.08 2.412 -2.412 2.412 h -2.245 c -1.332 0 -2.412 -1.08 -2.412 -2.412 V 30.799 c 0 -1.332 1.08 -2.412 2.412 -2.412 h 2.245 c 1.332 0 2.412 1.08 2.412 2.412 V 81.32 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
					</g>
				</svg>
			</Td>
		</Tr>
	);
};

export default TenantRow;
