export const PackOptions = [
	{ label: "Pack Pro", value: 6 },
	{ label: "Pack Pro +", value: 7 },
	{ label: "Pack Expert", value: 8 },
	{ label: "Pack Expert +", value: 9 },
];

export const LanguagesOptions = [
	{ label: "French", value: 1 },
	{ label: "English", value: 2 },
	{ label: "Dutch", value: 3 },
];

export const SeedOptions =
	process.env.REACT_APP_WEB_URL !== "https://qa.vertuoza.app"
		? [
				{ label: "sales_be_fr", value: 0 },
				{ label: "sales_be_nl", value: 1 },
				{ label: "sales_fr_fr", value: 2 },
				{ label: "sales_ch_fr", value: 3 },
		  ]
		: [
				{ label: "e2e", value: 0 },
				{ label: "product", value: 1 },
		  ];

export const CountriesOptions = [
	{ label: "Belgium", value: "PAYS_BELGIQUE" },
	{ label: "France", value: "PAYS_FRANCE" },
	{ label: "Luxembourg", value: "PAYS_LUXEMBOURG" },
	{ label: "Germany", value: "PAYS_ALLEMAGNE" },
	{ label: "Netherlands", value: "PAYS_PAYSBAS" },
	{ label: "Switzerland", value: "PAYS_SUISSE" },
	{ label: "Spain", value: "PAYS_ESPAGNE" },
	{ label: "Italy", value: "PAYS_ITALIE" },
];
