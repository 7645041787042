import {
	Button,
	Divider,
	FormControl,
	FormErrorMessage,
	HStack,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Text,
	VStack, InputGroup,
} from "@chakra-ui/react";

import {
	CountriesOptions,
	LanguagesOptions,
	PackOptions,
	SeedOptions,
} from "../../../screens/tenants/constants";
import { IEditTenantModalProps } from "./props";
import { IEditTenantForm } from "./types";
import { useForm } from "../../../utils/form";
import { IFields } from "../../../utils/form/types";
import { FormLabel } from "../../form-label";
import useQueries from "../../../screens/tenants/queries";
import { useApolloClient, useMutation } from "@apollo/client";
import { validationSchema } from "./validation";
import { useLocation } from "react-router-dom";
import { useRef, useState } from "react";
import { FaFileUpload } from 'react-icons/fa'

export type Option = {
	label: string;
	value: string;
};

const EditTenantModal = ({
	onClose,
	isOpen,
	tenant,
}: IEditTenantModalProps) => {
	// Attributes
	const [logoPreview, setLogoPreview] = useState("");

	const client = useApolloClient();
	const { UpdateTenant, CreateTenant } = useQueries();
	const { state } = useLocation();

	const domain = state?.prefix;

	// Mutations
	const [createTenant, { loading: isCreateLoading }] =
		useMutation(CreateTenant);

	const [updateTenant, { loading: isUpdateLoading }] =
		useMutation(UpdateTenant);

	const isLoading = isCreateLoading || isUpdateLoading;

	// Form
	const { getField, isDirty, submit, reset } = useForm<IEditTenantForm>({
		values: {
			pack: tenant?.pack ?? undefined,
			seed: undefined,
			name: tenant?.name ?? undefined,
			street: tenant?.address?.street ?? "",
			number: tenant?.address?.number ?? "",
			locality: tenant?.address?.locality ?? "",
			country: tenant?.address?.country ?? undefined,
			zipCode: tenant?.address?.postalCode ?? "",
			email: tenant?.email ?? "",
			language: tenant?.language ?? undefined,
			phone: tenant?.phone ?? "",
			secondaryEmail: tenant?.secondaryEmail ?? "",
			secondaryPhone: tenant?.secondaryPhone ?? "",
			tvaNumber: tenant?.tvaNumber ?? "",
			website: tenant?.website ?? "",
		},
		onSubmit: handleSubmit,
		schema: validationSchema(tenant?.id ?? undefined),
	});

	// Fields
	const fields: IFields<IEditTenantForm> = {
		name: getField("name"),
		pack: getField("pack"),
		seed: getField("seed"),
		street: getField("street"),
		number: getField("number"),
		zipCode: getField("zipCode"),
		locality: getField("locality"),
		country: getField("country"),
		email: getField("email"),
		secondaryEmail: getField("secondaryEmail"),
		secondaryPhone: getField("secondaryPhone"),
		language: getField("language"),
		phone: getField("phone"),
		tvaNumber: getField("tvaNumber"),
		website: getField("website"),
	};

	// Handler
	function handleSubmit(values: IEditTenantForm) {
		const input = {
			id: tenant?.id ?? undefined,
			email: values?.email,
			address: {
				street: values?.street,
				number: values?.number,
				country: values?.country,
				locality: values?.locality,
				postalCode: values?.zipCode,
			},
			name: values?.name,
			pack: values?.pack,
			phone: values?.phone,
			secondaryEmail: values?.secondaryEmail,
			secondaryPhone: values?.secondaryPhone,
			tvaNumber: values?.tvaNumber,
			website: values?.website,
			logo: logoPreview ?? null,
		};

		if (!!tenant) {
			updateTenant({
				variables: { input },
				onCompleted: onSuccess,
			});
			return;
		}

		createTenant({
			variables: {
				input: {
					...input,
					domaine: domain,
					language: values?.language,
					seed: values?.seed,
				},
			},
		});

		onSuccess();
	}

	async function onSuccess() {
		await client.refetchQueries({
			include: ["getTenants"],
		});
		setLogoPreview('');
		reset();
		onClose();
	}

	const handleClose = () => {
		setLogoPreview('');
		reset();
		onClose();
	};
	const fileInputRef = useRef<HTMLInputElement>(null);
	const handleButtonClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result as string;
				setLogoPreview(base64String); // Set the base64 string for preview
			};
			reader.readAsDataURL(file);
		}
	}

	// Render
	return (
		<Modal isCentered isOpen={isOpen} onClose={handleClose} size={"3xl"}>
			<ModalOverlay backdropFilter="blur(10px)" />
			<ModalContent height={"800px"}>
				<ModalHeader
					w="full"
					borderBottomWidth={1}
					borderColor="neutral.100"
				>
					<HStack
						w="full"
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<Text>
							{tenant ? "Informations" : "Create new tenant"}
						</Text>
						<ModalCloseButton position={"unset"} />
					</HStack>
				</ModalHeader>

				<ModalBody pt="1.5rem" pb="2.5rem" overflow={"scroll"}>
					<VStack w="full" alignItems={"flex-start"} spacing={"1rem"}>
						<HStack w="full" alignItems={"flex-start"}>
							<FormControl
								isRequired
								isInvalid={!!fields.name.meta.error?.message}
							>
								<FormLabel>Societe name</FormLabel>
								<Input
									{...fields.name}
									onChange={(e) =>
										fields.name.onChange?.(e?.target?.value)
									}
									placeholder="Enter societe name..."
								/>
								<FormErrorMessage>
									{fields.name.meta?.error?.message}
								</FormErrorMessage>
							</FormControl>
						</HStack>
						<HStack w="full" alignItems={"flex-start"}>
							<FormControl
								isRequired
								isInvalid={!!fields.pack.meta.error?.message}
							>
								<FormLabel>Pack</FormLabel>
								<Select
									placeholder="Select pack..."
									{...fields.pack}
									onChange={(e) =>
										fields.pack.onChange?.(
											Number(e?.target?.value)
										)
									}
								>
									{PackOptions.map((option) => (
										<option
											key={option.value}
											value={option.value}
										>
											{option.label}
										</option>
									))}
								</Select>
								<FormErrorMessage>
									{fields.pack.meta?.error?.message}
								</FormErrorMessage>
							</FormControl>
							{!tenant && (
								<FormControl
									isRequired
									isInvalid={
										!!fields.seed.meta.error?.message
									}
								>
									<FormLabel>Seed</FormLabel>
									<Select
										placeholder="Select seed..."
										{...fields.seed}
										onChange={(e) =>
											fields.seed.onChange?.(
												Number(e?.target?.value)
											)
										}
									>
										{SeedOptions.map((option) => (
											<option
												key={option.value}
												value={option.value}
											>
												{option.label}
											</option>
										))}
									</Select>
									<FormErrorMessage>
										{fields.pack.meta?.error?.message}
									</FormErrorMessage>
								</FormControl>
							)}
							{!tenant && (
								<FormControl
									isRequired
									isInvalid={
										!!fields.language.meta.error?.message
									}
								>
									<FormLabel>Language</FormLabel>
									<Select
										placeholder="Select language..."
										{...fields.language}
										onChange={(e) =>
											fields.language.onChange?.(
												Number(e?.target?.value)
											)
										}
									>
										{LanguagesOptions.map((option) => (
											<option
												key={option.value}
												value={option.value}
											>
												{option.label}
											</option>
										))}
									</Select>
									<FormErrorMessage>
										{fields.language.meta?.error?.message}
									</FormErrorMessage>
								</FormControl>
							)}
						</HStack>
						<HStack w="full" alignItems={"flex-start"}>
							<FormControl>
								<FormLabel>Logo</FormLabel>
								{logoPreview && (
									<img src={logoPreview} alt="Logo Preview" style={{ maxWidth: '200px', maxHeight: '200px', paddingBottom: '20px' }} />
								)}
								<InputGroup>
									<Input type="file" accept="image/*" hidden onChange={handleLogoUpload} ref={fileInputRef} />
									<Button leftIcon={<FaFileUpload />} variant={"primary"} onClick={handleButtonClick}>
										Upload
									</Button>
								</InputGroup>

							</FormControl>
						</HStack>
					</VStack>
					<Divider my="2.5rem" />
					<VStack
						alignItems={"flex-start"}
						w="full"
						spacing={"1.5rem"}
					>
						<Text fontWeight={"600"} fontSize={"18"}>
							Contact details
						</Text>
						<VStack alignItems={"flex-start"} w="full">
							<HStack w="full" alignItems={"flex-start"}>
								<FormControl
									isRequired
									isInvalid={
										!!fields.email.meta.error?.message
									}
								>
									<FormLabel>Email address</FormLabel>
									<Input
										{...fields.email}
										onChange={(e) =>
											fields.email.onChange?.(
												e?.target?.value
											)
										}
										placeholder="Enter email address..."
									/>
									<FormErrorMessage>
										{fields.email.meta?.error?.message}
									</FormErrorMessage>
								</FormControl>
								<FormControl>
									<FormLabel>
										Email address (secondary)
									</FormLabel>
									<Input
										{...fields.secondaryEmail}
										onChange={(e) =>
											fields.secondaryEmail.onChange?.(
												e?.target?.value
											)
										}
										placeholder="Enter email address (secondary)..."
									/>
								</FormControl>
							</HStack>
							<HStack w="full" alignItems={"flex-start"}>
								<FormControl
									isRequired
									isInvalid={
										!!fields.phone.meta.error?.message
									}
								>
									<FormLabel>Phone number</FormLabel>
									<Input
										{...fields.phone}
										onChange={(e) =>
											fields.phone.onChange?.(
												e?.target?.value
											)
										}
										placeholder="Enter phone number..."
									/>
									<FormErrorMessage>
										{fields.phone.meta?.error?.message}
									</FormErrorMessage>
								</FormControl>
								<FormControl>
									<FormLabel>
										Phone number (secondary)
									</FormLabel>
									<Input
										{...fields.secondaryPhone}
										onChange={(e) =>
											fields.secondaryPhone.onChange?.(
												e?.target?.value
											)
										}
										placeholder="Enter phone number (secondary)..."
									/>
								</FormControl>
							</HStack>
							<HStack w="full">
								<FormControl>
									<FormLabel>TVA number</FormLabel>
									<Input
										{...fields.tvaNumber}
										onChange={(e) =>
											fields.tvaNumber.onChange?.(
												e?.target?.value
											)
										}
										placeholder="Enter TVA number..."
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Website</FormLabel>
									<Input
										{...fields.website}
										onChange={(e) =>
											fields.website.onChange?.(
												e?.target?.value
											)
										}
										placeholder="Enter website..."
									/>
								</FormControl>
							</HStack>
						</VStack>

						<Divider my="1rem" />

						<VStack
							alignItems={"flex-start"}
							w="full"
							spacing={"1.5rem"}
						>
							<Text fontWeight={"600"} fontSize={"18"}>
								Address
							</Text>

							<VStack w="full">
								<HStack alignItems={"flex-start"} w="full">
									<FormControl>
										<FormLabel>Street</FormLabel>
										<Input
											{...fields.street}
											onChange={(e) =>
												fields.street.onChange?.(
													e?.target?.value
												)
											}
											placeholder="Enter street..."
										/>
									</FormControl>
									<FormControl flex={"40%"}>
										<FormLabel>Number</FormLabel>
										<Input
											{...fields.number}
											onChange={(e) =>
												fields.number.onChange?.(
													e?.target?.value
												)
											}
											placeholder="Enter number..."
										/>
									</FormControl>
								</HStack>
								<HStack alignItems={"flex-start"} w="full">
									<FormControl flex="50%">
										<FormLabel>Postal Code</FormLabel>
										<Input
											{...fields.zipCode}
											onChange={(e) =>
												fields.zipCode.onChange?.(
													e?.target?.value
												)
											}
											placeholder="Enter postal code..."
										/>
									</FormControl>
									<FormControl>
										<FormLabel>Locality</FormLabel>
										<Input
											{...fields.locality}
											onChange={(e) =>
												fields.locality.onChange?.(
													e?.target?.value
												)
											}
											placeholder="Enter locality..."
										/>
									</FormControl>
								</HStack>
								<FormControl>
									<FormLabel>Country</FormLabel>
									<Select
										{...fields.country}
										placeholder="Select country..."
										onChange={(e) =>
											fields.country.onChange?.(
												e?.target?.value
											)
										}
									>
										{CountriesOptions.map((option) => (
											<option
												key={option.value}
												value={option.value}
											>
												{option.label}
											</option>
										))}
									</Select>
								</FormControl>
							</VStack>
						</VStack>
					</VStack>
				</ModalBody>

				<ModalFooter borderTopWidth={1} borderColor="neutral.100">
					<Button
						variant={"primary"}
						isDisabled={!isDirty || (isLoading && !!tenant)}
						isLoading={isLoading && !!tenant}
						onClick={() => submit?.()}
					>
						Confirm
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default EditTenantModal;
