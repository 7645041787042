import * as Yup from "yup";
import { validationObject } from "../../../utils/yup";
import { IEditTenantForm } from "./types";

export const validationSchema = (tenantId?: number) => {
  if (tenantId !== undefined) {
    return validationObject<IEditTenantForm>({
      name: Yup.string().required(),
      pack: Yup.number().required(),
      language: Yup.number().optional(),
      email: Yup.string().email().required(),
      phone: Yup.string().required(),
    });
  }

  return validationObject<IEditTenantForm>({
    name: Yup.string().required(),
    pack: Yup.number().required(),
    language: Yup.number().required(),
    email: Yup.string().email().required(),
    phone: Yup.string().required(),
  });
};
