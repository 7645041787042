import {
  FieldArrayPath,
  FieldPath,
  PathValue,
  useFieldArray,
  useForm as useHookForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IFormProps } from "./props";
import { IField, IFieldMeta, IForm } from "./types";

export const useForm = <T extends object>(
  props?: Partial<IFormProps<T>>
): IForm<T> => {
  // Attributes
  const {
    register,
    formState: { isDirty, errors, submitCount, isValid },
    handleSubmit,
    getFieldState,
    watch,
    setValue,
    setError,
    control,
    reset,
    trigger,
    clearErrors,
    getValues,
  } = useHookForm<T>({
    defaultValues: props?.defaultValues,
    values: props?.values,
    // @ts-ignore
    resolver: props?.schema ? yupResolver(props.schema) : undefined,
    mode: "onSubmit",
  });

  // Functions
  function getField<V>(name: FieldPath<T>): IField<V> {
    const field = register(name);
    const state = getFieldState(name);

    const meta: IFieldMeta = {
      ...state,
    };

    if (props?.getErrorMessage && state.error && meta.error) {
      meta.error.initialMessage = state.error.message;
      meta.error.message = props.getErrorMessage(
        name
          .split(".")
          .filter((key) => isNaN(Number(key)))
          .join("."),
        state.error
      );
    }

    const onChange = async (value: V) => {
      if (submitCount && state.isTouched) {
        clearErrors(name);
      }

      setValue(name, value as PathValue<T, FieldPath<T>>, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: props?.mode === "onChange",
      });
    };

    return {
      name: field.name,
      onChange: onChange,
      value: watch(name),
      meta,
    };
  }

  // Hooks
  const useGetFieldArray = (name: FieldArrayPath<T>) =>
    useFieldArray({
      name,
      control: control,
    });

  return {
    isValid,
    isDirty,
    getField,
    submit: props?.onSubmit ? handleSubmit(props.onSubmit) : undefined,
    submitCount,
    errors,
    useFieldArray: useGetFieldArray,
    reset,
    trigger,
    setError,
    getValues,
  };
};
